import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../Layout";
import Hero from "../../components/Hero";
import AccentHeading from "../../components/AccentBar/AccentHeading";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import iconText from "../../components/Icons/iconText";
import CTABox from "../../components/CTABox";
import { Link } from "gatsby";
import diagram from "../../images/pages/services/ccaspsps/pmdiagram.svg";
import IntroBlock from "../../components/IntroBlock";
import { fontSize } from "@mui/system";

const CCASPPA = () => {
  return (
    <Layout>
      <SEO title="CC&S Management Model" />
      <Hero imageName="ccaspspsBackground">
        <div style={{ width: "50%" }}>
          <h1 className="whiteText servicesHeading">
            CC&S Management Model for Private and Public Services
          </h1>
        </div>
      </Hero>

      <section>
        <IntroBlock>
          <Breadcrumb>
            <Breadcrumb.Item as={Link} to="/services">
              Services
            </Breadcrumb.Item>
            <Breadcrumb.Item>CC&S Management Model for Private and Public Sectors</Breadcrumb.Item>
          </Breadcrumb>
          <h1>CC&S Management Model</h1>
          <p>
            TBL works with closely with clients to deploy the DMAIC (Define,
            Measure, Analyse, Improve & Control) lean and green methodology.
            This has proven results, helping clients reduce waste and cost in
            current state processes and highlights future opportunity savings by
            taking a future state view of the business process. TBL also
            considers innovation and lifecycle analysis when developing
            potential solutions as these can unlock both financial and strategic
            benefits.
          </p>
        </IntroBlock>

      <Col
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StaticImage
          src="../../images/pages/services/ccaspsps/leandiagram.svg"
          height={400}
          style={{ margin: "auto auto" }}
          alt=""
        />
      </Col>
      </section>
      
      <Container>
        <Row>
          <Col>
            <p>
              {" "}
              TBL offers contract consultants with a range of Six Sigma skills
              and experience. Whether you need Master Black Belts, Black Belts
              or Green Belts, TBL can deploy consultants with the required
              skills.
            </p>
          </Col>
        </Row>
      </Container>

      <section>
        <h2 style={{ paddingTop: "50px", textAlign: "center" }}>
          Lean & Green Toolkit
        </h2>
        <p style={{ textAlign: "center" }}>
          The TBL Lean & Green toolkit offers a range of analysis techniques
          that can be used to improve your operation:
        </p>
      </section>

      <Container>
        <Row>
          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/histogramIcon.jpg"
              height={120}
              alt=""
            />
            <p style={{ marginTop: 15, fontWeight: 600, fontSize: 20 }}>
              Histograms
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/spcIcon.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {" "}
              Statistical Process Control Charts
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/regression.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {" "}
              Regressions & Correlations
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/processmappingIcon.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Process Mapping
            </p>
          </Col>
        </Row>

        <Row style={{ marginTop: "30px" }}>
          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/qfd.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Quality Function Deployment
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/bbs.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Balanced Business Scorecard S5
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/pareto.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Pareto Analysis
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/icons/training.jpg"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Training
            </p>
          </Col>
        </Row>

        <p>
          TBL will be glad to transfer knowledge in practice-oriented training
          and workshops on Lean & Green management to you. The fundamental theme
          of our training is building a link between theory and practice in
          real-life organisational environments.
        </p>
      </Container>

      <Container>
        <Row>
          <Col>
            <h4 style={{ marginTop: 50, textAlign: "center" }}>
              Training & Workshops
            </h4>
            <p style={{ textAlign: "center" }}>
              Training can be booked as an in house-training at your premises.
            </p>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={6}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/planet-earth.png"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Lean & Green Fundamentals
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/round-table2.png"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Professional Lean Training & Mentoring
            </p>
          </Col>

          <Col
            xs={12}
            sm={6}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            <StaticImage
              src="../../images/pages/services/ccaspsps/consulting-2.png"
              height={120}
              alt=""
            />
            <p
              style={{
                marginTop: 15,
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {" "}
              Training & Workshops
            </p>
          </Col>
        </Row>
      </Container>

      <section>
        <Container fluid>
          <Row>
            <Col xs={12} sm={6}>
              <h2 style={{ paddingTop: 50 }}>Project Management</h2>
              <span className="smallDividerLeft"></span>
              <p>
                TBL can coach your team and lead by example as your Project
                Director or Project Mentor. In project-crisis in supporting you
                in stakeholder management and open the way to new solutions:{" "}
              </p>
            
            <ul style={{ paddingTop: 20, fontSize: 18 }}> 
              <li>Tailoring of suitable project management methods and standards</li>
              <li>Introduce and advocate agile approaches in your organization</li>
              <li>Coaching of Project Managers</li>
              <li>Workshops on selected project management topics for you and your team</li>
              <li>Development of Project Roadmap strategies and governance</li>
              <li>Initiation of a PMO and introduction of Project Portfolio Management</li>
              <li>Documentation of processes and process models</li>
              <li>Assessment and support of projects in crisis</li>
              <li>Lead projects as Project Director or Project Manager</li>
            </ul>
            <Row>
              <Col>
            <p style={{ color: '#707070', fontSize: 18 }}>TBL offers contract consultants with a range of MSc, APM and PRINCE 2 Practitioner skills and experience. Whether you need a Project Director, Program Manager or Project Manager, TBL can deploy consultants with the required skills tailored to fit your specific project needs.</p>
              </Col> 
            </Row> 
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "70px",

              }}
            >
              <img src={diagram} style={{  width: '85%', margin: 'auto auto' }} />
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default CCASPPA;
